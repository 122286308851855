@import "utilities";

.depositPanel {
  flex-direction: column;
  position: relative;
  width: 100%;
}

.arrow {
  margin: 0 !important;
  order: 2;
  position: absolute;
  top: 86px;
}

.first {
  margin: 4px 0 !important;
  order: 1;
  width: 100%;
}

.last {
  margin: 4px 0 !important;
  order: 3;
  width: 100%;
}

.masterPanel {
  display: flex;
  justify-content: center;
  width: 100%;
}

.innerPanel {
  background: $panelTop; /* Old browsers */
  background: -moz-linear-gradient(top, $panelTop 100%, $panelBottom 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, $panelTop 100%, $panelBottom 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, $panelTop 100%, $panelBottom 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$panelTop', endColorstr='$panelBottom', GradientType=0); /* IE6-9 */
  border-radius: 10px;
  display: flex;
  margin: 0 12px;
  padding: 15px;
  width: 100%;

  h2 {
    color: $black;
    font: {
      size: 25px;
      weight: normal;
    };
    margin-top: 20px;
    margin-left: 30px;
    text-align: left;
    width: 100%;
  }

  .btn {
    background-color: $active;
    border-radius: 5px;
    color: #192C65;
    font: {
      size: 16px;
      weight: bold;
    };
    height: 50px;
    width: 220px;

    &:disabled {
      cursor: default;
      opacity: .5;
    }
  }
}

.row {
  background-color: $biscotti;
  border-radius: 15px;
  display: flex;
  height: 110px;

  .box {
    flex: 1;
    margin: 0 0 0 34px;

    &:last-of-type {
      margin-right: 40px;

      .small {
        text-align: right;
      }
    }

    .small {
      font-size: 14px;
      line-height: 18px;
      margin: 8px 0;
    }

    .token {
      background-color: rgba($white, .28);
      border-radius: 25px;
      color: $normal;
      display: flex;
      font: {
        size: 16px;
        weight: bold;
      }
      line-height: 42px;
      height: 42px;
      text-transform: uppercase;
      width: 200px;

      img {
        margin: 8px;
      }
    }

    .max {
      background-color: $bgSidebar;
      border: 2px solid $ligthPanel;
      border-radius: 25px;
      color: $ligthPanel;
      cursor: pointer;
      font: {
        size: 16px;
        weight: bold;
      }
      line-height: 36px;
      height: 40px;
      margin-right: 20px;
      text-align: center;
      text-transform: uppercase;
      opacity: .56;
      width: 60px;
    }

    input {
      border: none;
      font-size: 28px;
      padding-left: 0;

      &:focus,
      &:focus-within,
      &:focus-visible {
        border: none;
        box-shadow: none;
        outline: none;
      }
    }

    .tokenBox {
      display: flex;
      height: 40px;
      justify-content: flex-end;
    }
  }
}