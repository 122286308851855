@import "utilities";

.panel {
  background: $panelTop; /* Old browsers */
  background: -moz-linear-gradient(top, $panelTop 100%, $panelBottom 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, $panelTop 100%, $panelBottom 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, $panelTop 100%, $panelBottom 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$panelTop', endColorstr='$panelBottom', GradientType=0); /* IE6-9 */

  border-radius: 10px;
  margin: 0 12px;
  padding: 15px;
  width: 50%;
}

.dashboard {
  h2 {
    font: {
      size: 25px;
      weight: normal;
    };
    margin-bottom: 20px;
    margin-left: 30px;
    text-align: left;
    width: 100%;
  }
}

.chartContainer {
  color: $black;
}