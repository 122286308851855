@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import "utilities";

body, html {
  color: white;
  font: {
    family: 'Courier New', monospace;
    size: 16px;
    weight: normal;
  }
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.App {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2a284c+0,08080f+100 */
  background: rgba($bg, .26); /* Old browsers */

  color: white;
  font: {
    family: 'Lato', sans-serif;
    size: 16px;
    weight: normal;
  }
  min-height: 100vh;
}

.error-message {
  color: #AC641C;
}