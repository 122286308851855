@import "utilities";

.panel {
  //background-color: $ligthPanel;
  border-radius: 10px;
  margin: 0 12px;
  padding: 15px;
  width: 100%;
}

.dashboard {
  color: $normal;
  h2 {
    font: {
      size: 25px;
      weight: normal;
    };
    margin-bottom: 20px;
    margin-left: 30px;
    text-align: left;
    width: 100%;
  }
}

.table {
  width: 100%;

  .row {
    background-color: rgba($white, .28);
    border-radius: 5px;
    margin-bottom: 5px;
    min-height: 70px;

    &.first {
      background-color: transparent;
      min-height: auto;
    }
  }

  .cell {

    //margin-inline: 0!important;

    width: 20%;

    &.first {
      padding-left: 20px;
      width: 30%;
    }

    &.last {
      width: 10%;
    }
  }
}