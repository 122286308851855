$white: #FFFFFF;
$black: #000000;

$mainColor: #FFEE00;
$active: #00BAD9;
$normal: #192C65;
$biscotti: #586681;

$bg: #B7D9DF;
$bgSidebar: #D0E6EB;
$panelTop: #D6E9EE;
$panelBottom: #E9F4F6;
$ligthPanel: #23213f;
$darkest: #0F0F1C;

//ffe900