@import "utilities";

.page {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
}

.logo {
  height: auto;
  width: 350px;
}

.login_button {
  background: {
    color: transparent;
    image: url("../images/polygon-logo.svg");
    position: 20px center;
    repeat: no-repeat;
  }
  border: 2px solid $active;
  border-radius: 50px;
  color: $active;
  display: inline-block;
  font: {
    size: 100%;
    weight: bold;
  }
  line-height: 24px;
  margin-top: 90px;
  outline: none;
  padding: 10px 30px 10px 60px;
}